import { Search, DropdownMenu, DropdownItem, Image, Tag, NavBar, Popup, List, Icon } from 'vant'
import Loading from '../../common/Loading.vue'
import Sift from '../common/Sift.vue'
import Share from '../../common/Share.vue'
import Login from '../../intercept/Login.vue'
import LogVisit from '../../common/LogVisit.vue'
import Clue from '../../common/Clue.vue'
export default {
    components: {
        Loading: Loading,
        Sift: Sift,
        Share: Share,
        Login: Login,
        LogVisit: LogVisit,
        Clue: Clue,
        [Search.name]: Search,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        [Image.name]: Image,
        [Tag.name]: Tag,
        [NavBar.name]: NavBar,
        [Popup.name]: Popup,
        [List.name]: List,
        [Icon.name]: Icon
    },
    data () {
        return {
            finished: false,
            loadingShow: false,
            keyword: '',
            classifyOptions: [{ text: '课程分类', value: '' }],
            classifyCode: '',
            condition: { classify: '', job: '', region: '', sort: '' },
            page: { current: 0, size: 10, last: 0 },
            taskList: []
        }
    },
    mounted () {
        document.title = '职位信息'
        var query = this.$route.query
        if (query.keyword !== undefined && query.keyword !== null) {
            this.keyword = query.keyword
        }
        if (query.classifyCode !== undefined && query.classifyCode !== null) {
            this.condition.classify = query.classifyCode
        }
        this.$refs.login.validate()
    },
    methods: {
        init () {
            this.$refs.logVisit.createLog()
            this.initCondition()
            this.retrieveTaskPage()
            this.initShare()
        },
        initShare () {
            var title = '好工作不求人，就上微职客'
            var desc = '国企、央企和事业单位等多种类型的多个职位任您选择'
            var link = 'https://moc.utopanet.com/recruit/search?keyword=' + this.keyword
            var logo = 'https://moc.utopanet.com/static/logo.png'
            this.$refs.share.initParam(title, desc, link, logo)
        },
        initCondition () {
            var condition = { classify: this.condition.classify }
            this.$refs.sift.initCondition(condition)
        },
        search () {
            this.taskList = []
            this.retrieveTaskPage()
            this.createSearchClue()
        },
        detail (code) {
            this.$router.push({ path: '/mde/hire/detail', query: { taskCode: code } })
        },
        siftCondition (condition) {
            this.condition.classify = condition.classify
            this.condition.job = condition.job
            this.condition.region = condition.region
            this.taskList = []
            this.page.current = 0
            this.page.last = 0
            this.finished = false
            this.retrieveTaskPage()
        },

        async retrieveTaskPage () {
            if (!this.finished) {
                this.loadingShow = true
                this.page.current = this.page.current + 1
                var pd = { keyword: this.keyword, classifyCode: this.condition.classify, jobCode: this.condition.job, regionCode: this.condition.region, current: this.page.current, size: this.page.size }
                const { data: res } = await this.$http.post(this.BMS_URL + '/hire/taskAPC/retrieveTaskPage', this.$qs.stringify(pd))
                if (res.status === '200') {
                    this.taskList = this.taskList.concat(res.data)
                    this.page.last = res.page.last
                    if (res.data.length <= 0) {
                        this.finished = true
                    }
                }
                this.loadingShow = false
            }
        },
        createSearchClue () {
            var date = new Date()
            var dt = date.getTime()
            var param = { keyword: this.keyword, url: window.location.href }
            var content = '补岗招聘搜索'
            this.$refs.clue.createClue('RECRUIT_SEARCH_RECRUIT', dt, JSON.stringify(param), content)
        },
        back () {
            this.$router.go(-1)
        }

    }
}
