import { DropdownMenu, DropdownItem, Popup, TreeSelect, Area } from 'vant'
export default {
    components: {
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
        [Popup.name]: Popup,
        [TreeSelect.name]: TreeSelect,
        [Area.name]: Area
    },
    data () {
        return {
            jobActiveId: '',
            jobActiveIndex: 1,
            job: { text: '选择岗位', id: '' },
            jobs: [{ id: '', text: '全部分类', children: [{ id: '', text: '全部分类' }] }],
            sorts: [
                { text: '选择排序', value: '' },
                { text: '薪资排序', value: 'SAR' },
                { text: '时间排序', value: 'TIM' }
            ],
            classify: '',
            classifys: [{ text: '选择分类', value: '' }],
            condition: { classify: '', region: '', job: '', sort: '' },
            regionPopupShow: false,
            regions: {},
            regionDefault: '610100',
            regionName: '选择城市'
        }
    },
    props: {
        callback: Function
    },
    mounted () {
        this.retrieveRegions()
        this.retrieveJobTree()
        this.retrieveClassifySelect()
    },
    methods: {
        async retrieveJobTree () {
            var pd = { groupUpper: '4' }
            const { data: res } = await this.$http.post(this.BMS_URL + '/data/job/retrieveJobTree', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.jobs = this.jobs.concat(res.data)
            }
        },
        classifyChange (item) {
            this.condition.classify = item
            this.callback(this.condition)
        },
        clickJobNav (index) {
            this.jobActiveIndex = index
        },
        clickJobItem (item) {
            this.job = item
            this.condition.job = item.id
            this.$refs.job.toggle()
            this.callback(this.condition)
        },
        confirmRegion (val) {
            var code = val[1].code
            this.condition.region = code
            this.regionName = val[1].name
            this.cacheCondition()
            this.callback(this.condition)
            this.$refs.region.toggle()
        },
        cancelRegion () {
            this.regionName = '选择城市'
            this.cacheCondition()
            this.callback(this.condition)
            this.$refs.region.toggle()
        },
        reset () {
            this.conditions.forEach(function (item) {
                item.checked = []
            })
        },
        async retrieveRegions () {
            var pd = { level: 3 }
            const { data: res } = await this.$http.post(this.UMS_URL + '/system/region/retrieveLevelRegion', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.regions.province_list = res.data.one
                this.regions.city_list = res.data.two
                this.regions.county_list = res.data.three
            }
        },
        async retrieveClassifySelect () {
            const { data: res } = await this.$http.post(this.BMS_URL + '/hire/classify/retrieveClassifySelect')
            if (res.status === '200') {
                res.data.forEach((item) => {
                    this.classifys.push({ text: item.name, value: item.code })
                })
            }
        },
        initCondition (condition) {
            this.classify = condition.classify
        },
        cacheCondition () {
            window.sessionStorage.setItem(window.const.global.RECRUIT_SEARCH_SIFT, JSON.stringify(this.condition))
        },
        initConditionCache () {
            var condition = window.sessionStorage.getItem(window.const.global.RECRUIT_SEARCH_SIFT)
            if (condition !== undefined && condition != null && condition !== '') {
                var condObj = JSON.parse(condition)
                this.condition = condObj
                this.callback(this.condition)
            }
        }
    }
}
