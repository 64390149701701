<template>
  <div class="page">
    <Login ref="login" :callback="init" />
    <div class="search">
      <van-row>
        <van-col span="3" @click="back()"
          ><div class="back-btn">
            <van-icon name="arrow-left" />返回
          </div></van-col
        >
        <van-col span="21">
          <van-search
            v-model="keyword"
            placeholder="请输入搜索关键词"
            ref="keyword"
            show-action
            ><template #action>
              <div @click="search">搜索</div>
            </template></van-search
          >
        </van-col>
      </van-row>
    </div>
    <Sift :callback="siftCondition" ref="sift" />
    <van-list
      :finished="finished"
      finished-text="没有更多了"
      offset="0"
      class="task-list"
      :immediate-check="false"
      @load="retrieveTaskList"
    >
      <template #default>
        <van-row
          class="task bdc-b1s-gray"
          v-for="task in taskList"
          :key="task.code"
          @click="detail(task.code)"
        >
          <van-col span="24" class="content">
            <van-row>
              <van-col span="24" class="name text-short"
                ><van-tag :color="COLOR_M" v-if="task.state === 'NML'"
                  >进行中</van-tag
                ><van-tag :color="COLOR_S1" v-if="task.state === 'CMP'"
                  >已招满</van-tag
                >
                {{ task.name }}</van-col
              >
            </van-row>
            <van-row>
              <van-col span="24" class="label">
                <span
                  v-for="item in task.labels"
                  :key="item.name"
                  class="item bgc-gray"
                  >{{ item.name }}</span
                >
              </van-col>
            </van-row>
            <van-row>
              <van-col span="14" class="time"
                ><van-icon name="underway-o" />&nbsp;{{ task.publishTime }}
              </van-col>
              <van-col span="10" class="salary fc-cyan"
                ><span v-if="task.salaryMin === 0 && task.salaryMax === 0"
                  >面议</span
                >
                <span v-if="task.salaryMin > 0">
                  <span v-if="task.salaryMin > 1000">
                    {{ (task.salaryMin / 1000).toFixed(1) }}K
                  </span>
                  <span v-else>{{ task.salaryMin }}元</span>
                </span>
                <span v-if="task.salaryMax > 0"
                  >-
                  <span v-if="task.salaryMax > 1000"
                    >{{ (task.salaryMax / 1000).toFixed(1) }}K</span
                  >
                  <span v-else>{{ task.salaryMax }}元</span></span
                >/<span v-if="task.salaryType === 'YER'">年</span>
                <span v-if="task.salaryType === 'MON'">月</span>
                <span v-if="task.salaryType === 'DAY'">天</span>
                <span v-if="task.salaryType === 'FQC'">次</span>
                <span v-if="task.salaryType === 'TIM'">小时</span>
                <span v-if="task.salaryType === 'MET'">米</span>
                <span v-if="task.salaryType === 'SMT'">平米</span></van-col
              >
            </van-row>
            <van-row>
              <van-col :span="18" class="company">
                <span
                  ><van-icon name="newspaper-o" />&nbsp;{{ task.supplierName }}
                  <van-tag :color="COLOR_S2" v-if="task.supplierType === 'DRT'"
                    >直营</van-tag
                  ></span
                ></van-col
              >
              <van-col span="6" class="amount"
                ><van-icon name="friends-o" />&nbsp;{{ task.count }}人</van-col
              >
            </van-row>
            <!-- <van-row>
              <van-col :span="18" class="address">
                <van-icon name="location-o" />&nbsp;{{ task.address }}</van-col
              >
              <van-col :span="6" class="distance"
                >距我{{ task.distance }} KM</van-col
              >
            </van-row> -->
          </van-col>
        </van-row>
        <van-row v-if="taskList.length <= 0">
          <van-col span="24" class="no-record">
            <van-image
              width="103"
              height="103"
              :src="require('../../../../assets/images/home/no-record.png')"
            ></van-image>
          </van-col>
        </van-row>
      </template>
    </van-list>
    <Clue ref="clue" />
    <Loading :show="loadingShow" />
    <LogVisit
      ref="logVisit"
      module-type="HRE"
      module-label="HRE_SEARCH_INDEX"
    />
    <Share ref="share" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
