<template>
  <div>
    <van-dropdown-menu class="dropdown">
      <van-dropdown-item
        v-model="classify"
        :options="classifys"
        ref="classify"
        @change="classifyChange"
      />
      <van-dropdown-item :title="job.text" ref="job"
        ><van-tree-select
          :active-id="jobActiveId"
          :main-active-index="jobActiveIndex"
          :items="jobs"
          @click-nav="clickJobNav"
          @click-item="clickJobItem"
      /></van-dropdown-item>
      <!-- <van-dropdown-item v-model="type" :options="types" />
      <van-dropdown-item v-model="sort" :options="sorts" /> -->
      <van-dropdown-item :title="regionName" ref="region">
        <van-area
          :value="regionDefault"
          :area-list="regions"
          columns-num="3"
          item-height="30"
          @cancel="cancelRegion"
          @confirm="confirmRegion"
        />
      </van-dropdown-item>
      <van-dropdown-item v-model="condition.sort" :options="sorts">
      </van-dropdown-item>
    </van-dropdown-menu>
  </div>
</template>
<script>
import Sift from './Sift.js'
export default {
  ...Sift
}
</script>
<style lang="less" scoped>
@import './Sift.less';
</style>
